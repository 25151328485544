import React from 'react';
import Layout from '../../layouts/Layout';
import { graphql, useStaticQuery } from 'gatsby';
import JobTable from '../../components/JobTable/JobTable';

const SearchPage = () => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);
	const menuItems = [
		{
			text: 'Home',
			href: '/',
			active: false
		},
		{
			text: 'Job Opportunities',
			href: '/search/',
			active: false
		},
		{
			text: 'Benefits',
			href: '/benefits/',
			active: false
		},
		{
			text: 'Worldwide Opportunities',
			href: 'https://www.boydcorp.com/company/about-us/career-opportunities.html',
			active: false
		}
	];
	return (
		<Layout
			menuItems={menuItems}
			title={'Boyd Careers'}
			desc={'Boyd Careers'}
		>
			<JobTable data={data} />
		</Layout>
	);
};

export default SearchPage;
